<template>
  <div>
    <CCard>
      <CCardHeader>
        <slot name="header">
          <div class="card-header-actions" style="text-align: right; padding-top: 2px;">
            <CButton color="info" size="sm" @click="showChallenge()" style="margin-right: 5px;">Ver Concurso</CButton>
          </div>
          <h3 style="margin-bottom: 0px;">{{challenge.name}}</h3>
        </slot>
      </CCardHeader>
      <CCardBody>
        <CRow>      
          <CCol md="12">
              <CRow>                           
                <CCol md="12">
                  <CWidgetIcon style="width: 160px; height: 120px; float: right;"
                    header="8.3"
                    text="ROI"
                    color="success"
                  >
                    <CIcon name="cil-balance-scale" width="24"/>                                    
                  </CWidgetIcon>               
                  <CWidgetSimple id="dates" style="width: 400px; height: 120px; float: right; margin-right: 10px;">
                    <div class="m-0" style="font-size: 32px; font-weight: bold; padding: 0px;">15 May - 30 Jun</div>
                    <div style="margin-top: 12px; font-weight: bold;">Dias transcurridos: 30</div>          
                  </CWidgetSimple>      
                  <CRow>
                    <CCol>
                      {{(challenge.type == 'sales' ? 'Venta Mínima' : (challenge.type == 'customers' ? 'Clientes Mínimos' : challenge.type == 'boxes' ? 'Cajas Mínimo' : ''))}}
                      <span v-if="challenge.type == 'boxes'"><br />{{(challenge.mechanic == 'total' ? 'Numero Total de Cajas Vendidas' : challenge.mechanic == 'ranges' ? 'Rangos de Cajas Vendidas' : 'Individual Por Cada Caja Vendida')}}</span>
                      <br /><br />
                      {{challenge.description}}
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
              <CRow>                           
                <CCol md="3">
                  <CWidgetIcon
                    id="total_sale"
                    header="$1,029,704"
                    text="Venta Total"
                    color="gradient-primary"
                  >
                    <CIcon name="cilMoney" width="24"/>
                  </CWidgetIcon>           
                    <CCard>
                      <CCardBody><GaugeSemaforoGraph ref="graphGauge" title="Clientes Alcanzados"></GaugeSemaforoGraph></CCardBody>
                    </CCard>
                </CCol>
                <CCol md="3">
                  <CWidgetIcon
                    id="total_balance"
                    header="$1.999,50"
                    text="Inversión Total"
                    color="gradient-primary"
                  >
                    <CIcon name="cilMoney" width="24"/>
                  </CWidgetIcon>                          
                  <CCard>
                    <CCardBody><DonoutGraph ref="graphDonout" title="Participación Vendedores:"></DonoutGraph></CCardBody>
                  </CCard>
                </CCol>     
                <CCol md="6">
                  <CCard>
                    <StackedBarGraph ref="stackedBarGraph" title="Desempeño por Ruta"></StackedBarGraph>
                  </CCard>
                </CCol>
              </CRow>              
          </CCol>                   
        </CRow>
        <CRow>          
          <CCol md="6">
            <SalesVsTarget ref="doubleBarHorizontalGraph" title="Desempeño por supervisor"></SalesVsTarget>
          </CCol>      
          <CCol md="6">
            <DoubleBarVerticalGraph ref="doubleBarVerticalGraph" title="Desempeño por CEDIS"></DoubleBarVerticalGraph>
          </CCol>                       
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>

import store from '../../store';
import challenges_ws from '../../services/challenges';

import DonoutGraph from '../../components/graphs/DonoutGraph.vue';
import StackedBarGraph from '../../components/graphs/StackedBarGraph.vue';
import GaugeSemaforoGraph from '../../components/graphs/GaugeSemaforo.vue';
import SalesVsTarget from '../../components/graphs/SalesVsTarget.vue';
import DoubleBarVerticalGraph from '../../components/graphs/DoubleBarVerticalGraph.vue';
import HomeMenu from '../../components/menu/HomeMenu.vue';

export default {
  name: 'ChallengeDashboard',
  components: {
    DonoutGraph,
    GaugeSemaforoGraph,
    HomeMenu,
    StackedBarGraph,
    SalesVsTarget,
    DoubleBarVerticalGraph
  },
  data () {
    return {
      challenge_id: 0,
      challenge: {
        'type': 'boxes'
      },
    }
  },
  mounted: async function() {
    let role = localStorage.getItem("role");

    if(role != 'administrator'){
      if(role == "supplier"){
        window.location.href = "/#/welcome"; return;
      }
      else{
        if(role == "manager"){
          window.location.href = "/#/welcome"; return;
        }
        else{
          window.location.href = "/#/404"; return;
        } 
      } 
    }

    let challenge_id = await this.$route.params.challenge_id;
    this.challenge_id = challenge_id;

    let response = await challenges_ws.show(this.challenge_id); 

    if(response.type == "success"){
      this.challenge = response.data;
    }

    this.loadGraphs();
  },
  methods: {
    async loadGraphs(){
        // await this.$refs.graphPercent.loadGraph(this.graphs_percent);
        await this.$refs.graphDonout.loadGraph([]);
        await this.$refs.graphGauge.loadGraph("");
        await this.$refs.stackedBarGraph.loadGraph("");     
        await this.$refs.doubleBarHorizontalGraph.loadGraph("");
        await this.$refs.doubleBarVerticalGraph.loadGraph("");
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    }
  }
}
</script>
<style>
  #dates .card-body{
    padding: 0px important!;
  }

  .text-value{
    font-size: 32px;
    text-align: right;
  }

  .text-muted{
    font-size: 18px;
    text-align: right;
  }

  #total_sale{
    .card-body{
      div:nth-child(2) {
        width: 100%;
      }
    }
  }

  #total_balance{
    .card-body{
      div:nth-child(2) {
        width: 100%;
      }
    }
  }
</style>
